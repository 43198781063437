import { inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, Observable, switchMap } from 'rxjs';

import { UserService } from '@ppg/auth';

import { GlobalSettings } from '../../data/models/globalSettings';
import { GetGlobalSettingsGQL } from '../../queries/get-global-settings.query';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  readonly #userService = inject(UserService);
  readonly #globalSettingsGQL = inject(GetGlobalSettingsGQL);
  public readonly globalSettings = toSignal(
    toObservable(this.#userService.user).pipe(
      filter((user) => !!user),
      switchMap((user) => {
        return this.#globalSettingsGQL.fetch(
          {
            marketKey: 'AC-' + user!.countryCode,
            locale: user!.locale ?? '',
          },
          { fetchPolicy: 'no-cache', context: { queryDeduplication: false } },
        );
      }),
      filter((response) => !!response),
      map((response) => response.data.configuration.globalSettings),
    ),
  );

  getGlobalSettingsAsAnonymous(language: string): Observable<GlobalSettings> {
    const countryCode = language.split('-').pop();

    return this.#globalSettingsGQL
      .fetch(
        {
          marketKey: 'AC-' + countryCode,
        },
        { fetchPolicy: 'no-cache', context: { clientName: 'anonymous', queryDeduplication: false } },
      )
      .pipe(map((response) => response.data.configuration.globalSettings));
  }
}
