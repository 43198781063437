import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, GlobalSettings } from '../data/models/globalSettings';

@Injectable({
  providedIn: 'root',
})
export class GetGlobalSettingsGQL extends Query<Configuration<GlobalSettings>, ConfigurationQueryVariables> {
  override document = gql`
    query ($marketKey: String!, $locale: String) {
      configuration(marketKey: $marketKey, locale: $locale) {
        globalSettings
      }
    }
  `;
}
